import React from "react"
import "../components/layout.css"
// import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { graphql } from "gatsby"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Footer from "../components/Navigation/Footer"
import Header from "../components/Navigation/Header"
import Seo from "../components/Seo"

import BigBox from "../components/BigBox.js"

import {
  BlogGrid,
  Container,
  ImgWrap,
  TitleWrap,
  Title,
  HeadingTwo,
  ArticleStyle,
  ArticleOL,
  ArticleUL,
  DatePub,
  Author,
  Category,
  Content,
  RichLink,
  QuoteBlock,
  BlogHeader,
  ColCont,
  Col1,
  Col2,
} from "./poststyles"

import { StyledFrame2, LinearTitle } from "../styles/elements.js"

// import { node } from "prop-types"

export const data = graphql`
  query pageQuery($slug: String) {
    contentfulCourse(slug: { eq: $slug }) {
      title
      subtitle
      author
      category
      datePublished(formatString: "MMMM Do YYYY")
      slug
      rich {
        raw
      }
      courseImage {
        gatsbyImageData(width: 700)
        title
      }
    }
  }
`

const PostLayout = ({ children, siteTitle, data }) => {
  const { rich } = data.contentfulCourse
  const image = getImage(data.contentfulCourse.courseImage)
  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return <img alt={alt} src={url} />
      },
      [BLOCKS.HEADING_1]: (node, children) => (
        <LinearTitle>{children}</LinearTitle>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <HeadingTwo>{children}</HeadingTwo>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 style={{ color: "white" }}>{children}</h3>
      ),
      [BLOCKS.QUOTE]: (node, children) => (
        <QuoteBlock>
          <p>{children}</p>
        </QuoteBlock>
      ),
      [BLOCKS.OL_LIST]: (node, children) => <ArticleOL>{children}</ArticleOL>,
      [BLOCKS.UL_LIST]: (node, children) => <ArticleUL>{children}</ArticleUL>,

      [INLINES.HYPERLINK]: node => {
        if (node.data.uri.includes("player.vimeo.com/video")) {
          return (
            <StyledFrame2
              title="Unique Title 001"
              src={node.data.uri}
              frameBorder="0"
              allowFullScreen
            ></StyledFrame2>
          )
        } else if (node.data.uri.includes("youtube.com/embed")) {
          return (
            <StyledFrame2
              title={node.data.uri}
              src={node.data.uri}
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              allowFullScreen
            ></StyledFrame2>
          )
        } else {
          return (
            <RichLink href={node.data.uri} target="_blank">
              {node.content[0].value}
              {console.log(
                "is there a node.content[0].value",
                node.content[0].value
              )}
            </RichLink>
          )
        }
      },

      // [INLINES.HYPERLINK]: (node, children) => <RichLink>{children}</RichLink>,
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p style={{ color: "#dcdcdc" }}>{children}</p>
      ),
    },
  }

  return (
    <>
      <Header />
      <Seo
        title={data.contentfulCourse.title}
        description={`CompoundYourself: ` + data.contentfulCourse.subtitle}
      />
      <Container>
        <div>
          <BlogGrid>
            <BlogHeader>
              <ImgWrap>
                {data.contentfulCourse.courseImage && (
                  <GatsbyImage image={image} alt={""} />
                )}
              </ImgWrap>
              <TitleWrap>
                <Title>{data.contentfulCourse.title}</Title>
                <ColCont>
                  <Col1>
                    <Author>Instructor</Author> <Category>Category </Category>{" "}
                    <DatePub>Review published</DatePub>
                  </Col1>
                  <Col2>
                    <Author> {data.contentfulCourse.author}</Author>
                    <Category>{data.contentfulCourse.category}</Category>
                    <DatePub>{data.contentfulCourse.datePublished}</DatePub>
                  </Col2>
                </ColCont>
              </TitleWrap>
            </BlogHeader>

            <ArticleStyle>
              <Content>{rich && renderRichText(rich, options)}</Content>
            </ArticleStyle>
            <BigBox />
          </BlogGrid>

          <main>{children}</main>
          <Footer />
        </div>
      </Container>
    </>
  )
}

export default PostLayout
